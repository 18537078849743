import React from "react"
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoff, faX, faDownload, faShare } from '@fortawesome/free-solid-svg-icons'

const random = require('canvas-sketch-util/random');

const Tile = ({color,imageUrl,thumbnailUrl,filename}) => {

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          maxHeight:'80vh',
          maxWidth:'86vw',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor:color,
          border:'0px'
        },
      };

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {

    }
  
    function closeModal() {
        setIsOpen(false);
    }

    function download(){
        fetch(imageUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'image/jpeg',
            },
          })
          .then((response) => response.blob())
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
              new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              filename,
            );
        
            // Append to html link element page
            document.body.appendChild(link);
        
            // Start download
            link.click();
        
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });

    }

    function share(){
        window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(imageUrl)+'&t='+encodeURIComponent(filename),'sharer','toolbar=0,status=0');

    }


return(
    <div className="Tile" style={{backgroundColor:color}} >
        <div className="image-wrapper" onClick={openModal}>
                <img className="image-main" src={thumbnailUrl} />
        </div>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
        >
            <div className="close-model" onClick={closeModal}><FontAwesomeIcon icon={faX}/></div>
            <div className="model-body" >
                <img className="large-image" src={thumbnailUrl} onClick={download}/>
                <h2 className="info-message">Click the download button to get the full quality version</h2>
            </div>
            <div className="button-bar">
                    <button onClick={download}><FontAwesomeIcon icon={faDownload} size="xl"/></button>&nbsp;
                    {/* <button onClick={share}><FontAwesomeIcon icon={faShare} /></button> */}
            </div>
        </Modal>
    </div>

)}

export default Tile