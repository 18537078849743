import logo from './logo.svg';
import './App.css';
import Gallery from './Gallery'
import Modal from 'react-modal';

function App() {

  
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  return (
    <div className="App">
      <header className="App-header">
        <Gallery  />
      </header>
    </div>
  );
}

export default App;
