import React,{ useEffect, useState } from "react"
import Tile from './Tile'

const random = require('canvas-sketch-util/random');

let colours = ["#95ad55","#f3db3d","#de4652","#cd5e29","#35294d","#6582ae","#6582ae"]

const Gallery = ({color,imageUrl}) => {
  
        const [tiles, setTiles] = useState([])
        const url = 'https://photogalleryapi.azurewebsites.net/API/GetGallery?id=123';

        useEffect(()=> {
            const response = fetch(url, {
                method: 'GET',
                mode:'cors',
                headers: {
                    'x-functions-key':'TsH7zTzJAzvPANxxTfVGHhZzTst9btF2PgMwjuZQMUwPAzFu-ynPUw==',
                  'Accept': 'application/json'
                  
                },
              })
              .then((res) => res.json())
              .then((data) => {
                let tiles = [];
                var gallery = JSON.parse(data);
                let lastColour = "";
                let nextColor = "#95ad55"; 
                
                for(let t=0; t< gallery.Photos.length; t++){
                  nextColor = random.pick(colours);
              
                  while(nextColor == lastColour){
                    nextColor =  random.pick(colours);
                  }
                  lastColour = nextColor;
              
                  tiles.push({name:'tile-'+t, title:"hi", color:nextColor,height:random.range(200,350)+"px", src:gallery.Photos[t].PhotoURL,thumbnail:gallery.Photos[t].ThumbnailURL,filename:gallery.Photos[t].FileName,width:"32%"});
                }
                setTiles(tiles);
              })
              .catch(error => console.log('Error while fetching:', error))
    
        },[url]);


        return ( 
            <div className="Gallery">
                <div className="gallery-header">
                    <img className="gallery-logo" src="./rondel.svg"/>
                    <h1>Ladies Night 2023</h1>
                    <h3>By Burton Round Table</h3>
                    {tiles.length == 0 &&
                        <h2>No images have been published yet, please come back soon!</h2>
                    } 
                    {tiles.length > 0 &&
                        <h2>Please scroll to find your photo and click to download</h2>
                    } 
                </div>

                {tiles.length > 0 &&
                    tiles.map(({color,title,height,width,src,name,thumbnail,filename}) => <Tile key={name} color={color} imageUrl={src} thumbnailUrl={thumbnail} filename={filename}/> )
                }
   
            </div>
        )


}

export default Gallery